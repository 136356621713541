import { Helmet } from "react-helmet";
import axios from "axios";
import FsLightbox from "fslightbox-react";
import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import ProgressiveImage from "react-progressive-image";
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";

function About() {
  const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");

  const handleToggler = (event) => {
    setToggler({
      toggler: !toggler,
    });
  };

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>About Me</title>
        <meta
          name="description"
          content="Personal Portfolio About Page"
        />
      </Helmet>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="About Me" />
          <div className="row">
            <div className="col-lg-6">
              <div className="mi-about-image">
                <ProgressiveImage
                  src={information.aboutImage}
                  placeholder="/images/about-image-placeholder.png"
                >
                  {(src) => (
                    <img
                      src={src}
                      alt="aboutimage"
                      onClick={() => handleToggler(!toggler)}
                    />
                  )}
                </ProgressiveImage>
                <span className="mi-about-image-icon">
                  <Icon.ZoomIn />
                </span>
                <FsLightbox
                  toggler={toggler}
                  sources={[information.aboutImageLg]}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mi-about-content">
                <h3>
                  I build things on the <span className="color-theme">web</span>.
                </h3>
                <p>
                  Hello! I build things on the web and I have over 4 years of experience in leadership, 
                  communication, and management. I'm knowledgeable in the finance industry, 
                  insurance and start-up sector. Strong ability to adapt quickly 
                  and build strong team relationships and provide quality work results. 
                  I have a passion for finding different and creative solutions to a 
                  complex problem. <span role="img" aria-label="Smile emoji">😊</span>
                </p>
                <p>
                  On my free time, I enjoy relaxing at home with a nice book by my side
                  and some boba tea (big fan of mango green tea). I also like to go on walks 
                  when the weather is nice. I also have some pets! I have a cute-crazy ferret
                  named Mia and a beta fish who's name is shen who is acoompanied by 3 snails.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
