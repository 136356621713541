import Mock from "../mock";

const database = {
  information: {
    name: 'Johnathan Guzman',
    first_name: 'Johnathan',
    aboutContent: "I'm a full stack web developer.",
    age: 24,
    phone: '',
    nationality: 'American',
    language: 'English, French',
    email: '',
    address: '121 King Street, Melbourne, Australia',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/johnathan-guzman/',
      dribbble: '',
      github: 'https://github.com/johnathanthedev'
    },
    brandImage: '/images/pfp.jpeg',
    aboutImage: '/images/mtn.jpg',
    aboutImageLg: '/images/mtn.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'color-pallet',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "Html/Css"
    },
    {
      title: "Javascript"
    },
    {
      title: "Bootstrap"
    },
    {
      title: "Material-UI"
    },
    {
      title: "Scss"
    },
    {
      title: "jQuery"
    },
    {
      title: "React/Redux"
    },
    {
      title: "Node"
    },
    {
      title: "Express"
    },
    {
      title: "Handlebars"
    },
    {
      title: "Ruby"
    },
    {
      title: "Ruby on Rails"
    },
    {
      title: ".NET"
    },
    {
      title: "gRPC"
    },
    {
      title: "Docker"
    },
    {
      title: "Kubernetes"
    },
    {
      title: "Elasticsearch"
    },
    {
      title: "Postgresql"
    },
    {
      title: "Sqlite"
    },
    {
      title: "MongoDB"
    },
    {
      title: "Ubuntu"
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "insureonline.com",
      subtitle: "Online insurance platform.",
      imageUrl: "/images/insureonlinedotcom.jpg",
      largeImageUrl: ["/images/insureonlinedotcom.jpg"],
      url: 'https://insureonline.com/'
    },
    {
      id: 2,
      title: "auto.insureonline.com",
      subtitle: "Insureonline landing page.",
      imageUrl: "/images/iolandingpage.jpg",
      largeImageUrl: [
        "/images/iolandingpage.jpg",
      ],
      url: 'https://auto.insureonline.com/'
    },
    {
      id: 3,
      title: "auto-quote.insureonline.com",
      subtitle: "Online marketplace to purchase car insurance.",
      imageUrl: "/images/autoquoteio.jpg",
      url: 'https://auto-quote.insureonline.com/'
    },
    {
      id: 4,
      title: "order.hungr.com",
      subtitle: "Food ordering platform",
      imageUrl: "/images/orderhungr.jpg",
      largeImageUrl: ["/images/orderhungr.jpg"],
      url: 'https://order.hungr.com/'
    },
    {
      id: 5,
      title: "hungr.com",
      subtitle: "Order managing platform",
      imageUrl: "/images/hungr.jpg",
      largeImageUrl: ["/images/hungr.jpg"],
      url: "https://www.hungr.com/restaurants/index.html"
    },
    {
      id: 6,
      title: "Zenyx, LLC",
      subtitle: "Agency home website.",
      imageUrl: "/images/zenyx.jpg",
      largeImageUrl: ["/images/zenyx.jpg"],
      url: "https://zenyx.io/"
    },
    {
      id: 7,
      title: "Superior Pristine Cleaning Company",
      subtitle: "Cleaning company home website.",
      imageUrl: "/images/spcc.jpg",
      largeImageUrl: ["/images/spcc.jpg"],
      url: 'https://superiorpristinecleaningcompany.netlify.app/'
    },
    {
      id: 8,
      title: "Vitl Media",
      subtitle: "Agency site.",
      imageUrl: "/images/vitl.jpg",
      largeImageUrl: ["/images/vitl.jpg"],
      url: "vitl-media.com"
    },
    {
      id: 9,
      title: "Natours",
      subtitle: "Brochure site.",
      imageUrl: "/images/natours.jpg",
      largeImageUrl: ["/images/natours.jpg"],
      url: "https://natour-wilderness.netlify.app/"
    },
    {
      id: 10,
      title: "MDP Cleaning",
      subtitle: "Cleaning services website.",
      imageUrl: "/images/mdp.jpg",
      largeImageUrl: ["/images/mdp.jpg"],
      url: 'https://mdprofessionalcleaning.netlify.app/'
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "03/2021 - 10/2021",
        position: "Web Developer",
        company: "Preferred Risk Insurances Services",
        details: "Contributed to the development and deployment of a cloud based application that helped the company generate online income by allowing users to purchase insurance through the web. Technologies used: Docker, Kubernetes, Rails, gRPC and React. Developed a robust email service in .NET that allows for custom email sending using SendGrid as an email delivery service or using Microsoft Exchange to send SMTP emails. Provided support in maintaining legacy applications running on legacy .NET and older versions of jquery. Quickly adapted to a shifting technology atmosphere."
      },
      {
        id: 2,
        year: "10/2020 - 12/2020",
        position: "Software Engineer",
        company: "Hungr",
        details: "Responsible for building and maintaining the frontend UI of home and login/signup screens of the web app. Collaborated with the project manager, designers, and other in-house developers when building out the main project. Established and analyzed frontend architecture using react and context API. Guided junior developers when debugging node.js and express.js backend. Engaged in weekly sprints."
      },
      {
        id: 3,
        year: "09/2019 - 10/2020",
        position: "Full-Stack Software Engineer",
        company: "Zenyx, LLC",
        details: "Responsible for creating and maintaining a suite of cutting-edge web applications, point-of-sale APIs, and complex API interactions from single-page applications. Managed the production of full stack projects using react, redux, node, express, and mongodb. Wrote clean, well-designed, scalable code. Produced dynamic web apps using html, css, react, bootstrap, and occasionally material ui. Created component-based architecture for reusability, uniformity, and efficiency. Provided guidance around front-end best practices and documentation and while researching and exploring new frameworks/libraries the team can be leveraging. Participated in brainstorming sessions and have the chance to contribute innovative and original ideas to the technology platform in a highly collaborative, team-oriented environment."
      },
      {
        id: 4,
        year: "06/2017 - 12/2018",
        position: "Freelance Developer",
        company: "",
        details: "Designed and developed CMS and E-commerce products in Ruby On Rails. Streamlined personal client invoices and project scope development by building a tool that allowed users of both parties to be synchronized across all project phases. Built responsive email campaigns and sent emails through Rails. Launched own marketing campaigns using landing pages built in an MVC and non-MVC."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2018 - 2019",
        graduation: "Master of Science",
        university: "Abc University",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 2,
        year: "2016 - 2018",
        graduation: "Bachelor of Science",
        university: "Abc University",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 3,
        year: "2015 - 2016",
        graduation: "Higher Schoold Graduation",
        university: "Abc College",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+312-586-0047'],
    emailAddress: ['johnathanguzman17@gmail.com']
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});